/*
    
    styles in game launching page

*/

html.utility-gameLaunchPage {
    height: 100%;
}

.utility-gameLaunchPage body {
    height: 100%;
    background: #ffd452;
}

.utility-gameLaunchPage main {
    text-align: center;
    width: 40em;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10em;
}

.utility-gameLaunchPage main > div {
    display: block;
    height: 1em;
    border: solid 0.2em black;
    position: relative;
}

.utility-gameLaunchPage main > div > div {
    background: red;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 100%;
    animation: progressing 3s infinite;
}

.utility-gameLaunchPage main p {
    font-size: 3em;
    font-family: "Microsoft Yahei", "微软雅黑", Tahoma, Helvetica, Arial, STXihei, "华文细黑", sans-serif;
}

@keyframes progressing {
    0% {
        right: 100%;
    }
    100% {
        right: 0;
    }
}


/*

    loader的style； 使用span.loader；需要的时候给它加上 formSubmitted class即可

*/

.state-inAjax {
    cursor: progress !important;
}

.state-completeAjax {
    cursor: initial !important;
}

.state-hidden {
    display: none!important;
}

.state-rawHidden {
    visibility: hidden!important;
    position: absolute!important;
}


/*
        START
        styled select

*/

div.styledSelect {
    float: left;
    overflow: hidden;
    background: url("/images/member/select.png") no-repeat;
    background-size: 191px 35px;
    width: 191px;
    height: 35px;
    display: inline-block;
}

div.styledSelect select {
    border: none;
    width: 120%;
    background: none;
    height: 100%;
    outline: none;
}


/*
        END
        styled select

*/


/*
        START
        styled dataTable

*/

table.table.dataTable tbody tr.selected td,
table.table.dataTable tbody tr.selected:hover td {
    background: #B0BED9;
}


/*
        END
        styled dataTable

*/

.jump_animation {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background: rgba(255, 255, 255, .7);
}

.jump_animation div.animation-wrapper {
    height: 100px;
    width: 100px;
    right: 0;
    left: 0;
    z-index: 11;
    top: 20vw;
    display: block;
    position: relative;
    margin: auto;
}

.rotating-plane {
    display: block;
    width: 100%;
    height: 100%;
    background-color: #333333;
    margin: 0 auto;
    -webkit-animation: rotateplane 1.2s infinite ease-in-out;
    animation: rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes rotateplane {
    0% {
        -webkit-transform: perspective(120px);
    }
    50% {
        -webkit-transform: perspective(120px) rotateY(180deg);
    }
    100% {
        -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
    }
}

@keyframes rotateplane {
    0% {
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
        transform: perspective(120px) rotateX(0deg) rotateY(0deg)
    }
    50% {
        -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
    }
    100% {
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg)
    }
}

span.loader {
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #3498db;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    position: relative;
    left: 100px;
    top: 5px;
    display: none;
}

span.loader.formSubmitted {
    display: block;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


/*
    invalid message 的 框；
    一次最多显示一行／一类错误；优先显示重要的
*/

@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.articulate_ajaxForm span.invalid_msg {
    position: absolute;
    color: red;
    display: none;
    font-size: 12px;
}

.articulate_ajaxForm span.invalid_msg.state-displaying {
    -webkit-animation: fadein 0.3s 1;
    animation: fadein 0.3s 1;
    display: inline-block;
}


/*

    可移动的placeholder／field title的style
    主要依靠 1 blur event ｜ 2 filled class

*/

.articulate_ajaxForm label span.field_title {
    position: absolute;
    display: block;
    top: 7px;
    left: 107px;
    font-size: 16px;
    background: #f3f3f3;
    pointer-events: none;
}

.articulate_ajaxForm label input.filled + span.field_title {
    top: -10px;
    font-size: 13px;
}

.articulate_ajaxForm label input.filled:focus + span.field_title {
    animation: initial;
}

.articulate_ajaxForm label input:focus + span.field_title {
    animation: placeholderTextAnimation 0.2s;
    top: -10px;
    font-size: 13px;
}

@keyframes placeholderTextAnimation {
    0% {
        top: 7px;
        font-size: 16px;
    }
    100% {
        top: -10px;
        font-size: 13px;
    }
}


/*
    styles for immiComplete module
*/

.immComplete-matches {
    position: absolute;
    border: solid 1px #ddd;
    width: 154px;
    max-height: 100px;
    right: 0;
    border-top-width: 0;
    overflow-y: auto;
    background-color: white;
    z-index: 10000;
}
.immComplete-matches ul{
    padding:0;
}
.immComplete-wrapper {
    z-index: 9999;
    background: transparent;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

